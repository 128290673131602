import request from '@/utils/request'


// 查询用户与传感器设备绑定列表
export function listUserSensor(query) {
  return request({
    url: '/userSensor/userSensor/list',
    method: 'get',
    params: query
  })
}

// 查询用户与传感器设备绑定分页
export function pageUserSensor(query) {
  return request({
    url: '/userSensor/userSensor/page',
    method: 'get',
    params: query
  })
}

// 查询用户与传感器设备绑定详细
export function getUserSensor(data) {
  return request({
    url: '/userSensor/userSensor/detail',
    method: 'get',
    params: data
  })
}

// 新增用户与传感器设备绑定
export function addUserSensor(data) {
  return request({
    url: '/userSensor/userSensor/add',
    method: 'post',
    data: data
  })
}

// 修改用户与传感器设备绑定
export function updateUserSensor(data) {
  return request({
    url: '/userSensor/userSensor/edit',
    method: 'post',
    data: data
  })
}

// 删除用户与传感器设备绑定
export function delUserSensor(data) {
  return request({
    url: '/userSensor/userSensor/delete',
    method: 'post',
    data: data
  })
}
